.line {
    border: 0;
    background-color: #BF0101;
    height: 3px; 
    cursor: pointer;
  }
  .red {
    background-color: red;
  }

  img.facebook_logo {
    height: 50%;
    width: 50%;
  }